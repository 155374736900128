<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Manage Download Center" :badge="total" />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col>
        <v-sheet
          key="sheets-status"
          elevation="2"
          height="32"
          rounded
          class="d-inline-flex align-center justify-center pl-1 pr-1"
        >
          <!-- pl-3 pr-1 -->
          <!-- <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4> -->
          <!-- class="ml-3" -->
          <v-btn-toggle
            class="ml-0"
            v-model="options.status"
            mandatory
            color="deep-purple"
            dense
            group
            style="height: 32px"
          >
            <v-btn
              :value="null"
              color="white"
              class="mx-0"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-file-signature mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">All</span>
            </v-btn>
            <v-divider vertical inset class="mx-2"></v-divider>
            <v-btn
              :value="enums.DownloadStatus.Succeeded.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad mr-2" :class="enums.DownloadStatus.Succeeded.iconAlt"></i>
              <span style="text-transform: none" class="font-weight-medium">{{
                enums.DownloadStatus.Succeeded.text
              }}</span>
            </v-btn>
            <v-btn
              :value="enums.DownloadStatus.Inprogress.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad mr-2" :class="enums.DownloadStatus.Inprogress.iconAlt"></i>
              <span style="text-transform: none" class="font-weight-medium">{{
                enums.DownloadStatus.Inprogress.text
              }}</span>
            </v-btn>
            <v-btn
              :value="enums.DownloadStatus.Pending.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad mr-2" :class="enums.DownloadStatus.Pending.iconAlt"></i>
              <span style="text-transform: none" class="font-weight-medium">{{
                enums.DownloadStatus.Pending.text
              }}</span>
            </v-btn>
            <v-btn
              :value="enums.DownloadStatus.Failed.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad mr-2" :class="enums.DownloadStatus.Failed.iconAlt"></i>
              <span style="text-transform: none" class="font-weight-medium">{{
                enums.DownloadStatus.Failed.text
              }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 download-center-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.fileName`]="{ item }">
        <h4>{{ item.fileName }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <download-status :status="item.status"></download-status>
      </template>

      <template v-slot:[`item.templateType`]="{ item }">
        <template-type :type="item.templateType"></template-type>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          color="green darken-1 py-0"
          :dark="item.url != null && item.url != ''"
          :href="item.url"
          :download="item.fileName"
          :disabled="item.url == null || item.url == ''"
        >
          <span class="fs-10px">
            <i class="far fa-arrow-down" style="margin-right: 0.5rem"></i
            ><span style="font-weight: 600">Download </span>
          </span>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import downloadCenterAPI from "../services/download-center-service.js";
import manageDownloadCenterHeader from "../config/tables/manageDownloadCenter.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  DownloadTypeFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";
import DownloadStatus from "../components/DownloadStatus.vue";
import TemplateType from "../components/TemplateType.vue";

export default {
  components: { FilterManager, DownloadStatus, TemplateType },
  data() {
    return {
      perms,
      enums,
      downloadCenterTimerId: null,
      storageKey: "manage-download-center",
      selectedFilters: [],
      total: 0,
      entities: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        status: [],
        templateTypes: [],
        sortBy: ["createDate"],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: manageDownloadCenterHeader,
    };
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var downloadTypeFilterSettings = new DownloadTypeFilterSettings();

      this.selectedFilters = [createdByFilterSettings, downloadTypeFilterSettings];
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/manage-download-center",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/manage-download-center", replace: true });
    },
    getData() {
      this.$backToTop(0, document.querySelector(".download-center-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      downloadCenterAPI
        .queryManagement(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.$handleError(err);
          this.loadingStates.table = false;
        });
    },
    getDataDebounced() {
      if (this.downloadCenterTimerId == null) {
        this.downloadCenterTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.downloadCenterTimerId);

      // delay new call 400ms
      this.downloadCenterTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    checkForSingleLayoutViewRoute(route) {
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openLayoutView(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
// .download-center-table {
//   tbody tr:not(.v-data-table__empty-wrapper) {
//     cursor: pointer;
//   }
// }
</style>
